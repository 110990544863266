import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import MicrosoftIcon from "@mui/icons-material/Microsoft";
import { initializeFirestore } from 'firebase/firestore';

const baseUrl = window.location.origin;

// var isProduction = false;

// if (baseUrl.indexOf("app.xpdtr") > -1 && baseUrl.indexOf("dev-nyc") === -1) {
//   isProduction = true;
// }

function checkIsProduction() {
  let url1 = process.env.REACT_APP_PROD_URL;
  let url2 = process.env.REACT_APP_DEV_URL;
  let isUrlProduction = false;
  if (window.location.origin.indexOf(url1) > -1 && window.location.origin.indexOf(url2) === -1) {
    isUrlProduction = true;
    return isUrlProduction;
  }
  return isUrlProduction
}

let isProduction = checkIsProduction();
const firebaseConfig = isProduction
  ? {
    apiKey: process.env.REACT_APP_PROD_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_PROD_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROD_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_PROD_FIREBASE_STORAGE_BUCKET,
    messagingSenderId:
      process.env.REACT_APP_PROD_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_PROD_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_PROD_FIREBASE_MEASUREMENT_ID,
  }
  : {
    apiKey: process.env.REACT_APP_DEV_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_DEV_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_DEV_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_DEV_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_DEV_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_DEV_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_DEV_FIREBASE_MEASUREMENT_ID,
  };

const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: "popup",
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: "/",
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    /* firebase.auth.FacebookAuthProvider.PROVIDER_ID, */
    {
      provider: "microsoft.com",
      providerName: "Microsoft",
      buttonColor: "#2F2F2F",
      iconUrl: "/icons8-microsoft.svg",
      // Custom parameters for the Microsoft OAuth provider
      customParameters: {
        // Prompt user for consent.
        prompt: "consent",
        // login_hint: "user@firstadd.onmicrosoft.com",
      },
    },
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
    /*  firebase.auth.PhoneAuthProvider.PROVIDER_ID,
    firebase.auth.TwitterAuthProvider.PROVIDER_ID, */
  ],
  // callbacks: {
  //   // Avoid redirects after sign-in.
  //    signInSuccessWithAuthResult: () => false,



  // },
  //   callbacks: {
  //     signInSuccessWithAuthResult: function(authResult, redirectUrl) {
  //         // Action if the user is authenticated successfull

  //         console.log("Is New User:",  authResult.additionalUserInfo.isNewUser);

  //        return false;
  //     },

  // },
  callbacks: {
    signInSuccessWithAuthResult: function (authResult, credential, redirectUrl) {
      // Action if the user is authenticated successfull


      return false;
    },

  },


};

const firebaseApp = firebase.initializeApp(firebaseConfig);
 const db = firebaseApp.firestore();
const auth = firebase.auth();

export { auth, db, uiConfig, firebaseApp,checkIsProduction };

export const nextPushId = () => {
  // Modeled after base64 web-safe chars, but ordered by ASCII.
  var PUSH_CHARS =
    "-0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz";
  // Timestamp of last push, used to prevent local collisions if you push twice
  // in one ms.
  var lastPushTime = 0;
  // We generate 72-bits of randomness which get turned into 12 characters and
  // appended to the timestamp to prevent collisions with other clients. We
  // store the last characters we generated because in the event of a collision,
  // we'll use those same characters except "incremented" by one.
  var lastRandChars = [];
  return function (now = new Date().getTime()) {
    var duplicateTime = now === lastPushTime;
    lastPushTime = now;
    var i;
    var timeStampChars = new Array(8);
    for (i = 7; i >= 0; i--) {
      timeStampChars[i] = PUSH_CHARS.charAt(now % 64);
      // NOTE: Can't use << here because javascript will convert to int and lose
      // the upper bits.
      now = Math.floor(now / 64);
    }
    var id = timeStampChars.join("");
    if (!duplicateTime) {
      for (i = 0; i < 12; i++) {
        lastRandChars[i] = Math.floor(Math.random() * 64);
      }
    } else {
      // If the timestamp hasn't changed since last push, use the same random
      // number, except incremented by 1.
      for (i = 11; i >= 0 && lastRandChars[i] === 63; i--) {
        lastRandChars[i] = 0;
      }
      lastRandChars[i]++;
    }
    for (i = 0; i < 12; i++) {
      id += PUSH_CHARS.charAt(lastRandChars[i]);
    }
    return id;
  };
};
