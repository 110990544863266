// InfiniteScrollSelect.js
import { useState, useEffect, useMemo, useContext, useRef } from "react";
import {
  TextField,
  MenuItem,
  CircularProgress,
  Select,
  InputLabel,
  FormControl,
  ListSubheader,
  InputAdornment,
  IconButton,
  Tooltip
} from "@mui/material";
// fetchData.js
import {
  collection,
  query,
  orderBy,
  limit,
  startAfter,
  getDocs,
  documentId,
  where,
  or,
  and,
  getCountFromServer,
  doc,
  getDoc
} from "firebase/firestore";
import { checkSearchLnth, db } from "config/config";
import { Search, Info as InfoIcon} from "@mui/icons-material";
import { Context } from "context/Wrapper";
import { GridClearIcon } from "@mui/x-data-grid";
import tableConfig from "config/advancedTableColumnsControl.json";


let searchTimeout = null,
  filterSearchedTimeout = null;
const searchLimit = tableConfig?.searchMinLength;

export const fetchData = async ({
  lastVisible,
  collectionName,
  searchText = "",
  conditions = [],
  label,
  batchSize = 10,
}) => {
  let q;

  if (conditions?.length === 0) return { data: [], lastVisible: null };

  if (lastVisible)
    q = query(
      collection(db, collectionName),
      orderBy(documentId(), "desc"),
      startAfter(lastVisible),
      limit(batchSize),
      ...conditions
    );
  else
    q = query(
      collection(db, collectionName),
      orderBy(documentId(), "desc"),
      limit(batchSize),
      ...conditions
    );

  let count = 0;

  if (`${searchText}`.trim()?.length > searchLimit) {
    q = query(
      collection(db, collectionName),
      orderBy(label, "desc"),
      orderBy(documentId(), "desc"),
      // and(
      //   where(label, ">=", searchText),
      //   where(label, "<=", searchText + "\uf8ff"),
      ...conditions
      // )
    );
    count = (await getCountFromServer(q))?.data()?.count;
  } else
    count = (
      await getCountFromServer(
        query(collection(db, collectionName), ...conditions)
      )
    )?.data()?.count;

  const snapshot = await getDocs(q);
  const data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

  const lastVisibleDoc =
    searchText?.length > 0 ? null : snapshot.docs[snapshot.docs.length - 1];
    
  return { data, lastVisible: lastVisibleDoc, count };
};




const InfiniteScrollSelect = ({
  collectionName = "",
  label = "name",
  inputLabel,
  onChange,
  value,
  name,
  data,
}) => {
  const selectElem = useRef(null);
  const context = useContext(Context);


  const { groupId, isPro, userId } = context?.state?.userProfile || {};
  const { showAllDataFlag } = context?.state || {};

  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastVisible, setLastVisible] = useState(null);
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [count, setCount] = useState(0);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [allDataLoaded, setAllDataLoaded] = useState(false);

  useEffect(() => {
      setOptions(data);
  }, [data]);

  useEffect(() => {
    if (searchText?.length < searchLimit && searchText?.length !== 0) return;
    if (filterSearchedTimeout !== null) clearTimeout(filterSearchedTimeout);

    filterSearchedTimeout = setTimeout(() => {
     let newArr = options
        ?.filter((item) =>
          item?.[label]?.toLowerCase().includes(searchText.toLowerCase())
        )
        .map((item) => {
          let newValue = item?.[label]?.replace(
            new RegExp(searchText, "gi"),
            (match) =>
              // `<mark style="background: #2769AA; color: white;">${match}</mark>`
            match
          );

        


          return {
            ...item,
            [label]: newValue,
          };
        });



      setFilteredOptions(newArr);
    }, 500);
  }, [options, searchText]);




  useEffect(() => {
    setAllDataLoaded(false);
  }, [showAllDataFlag]);

  useEffect(() => {
    // if (count === options?.length && count !== 0) return;
    if (allDataLoaded) return;

    setOptions([]);
    setLastVisible(null);
    loadOptions({ lastVisible: null, options: [] });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [`${showAllDataFlag}`, searchText, `${userId}`]);

  const loadOptions = async (props) => {
    setLoading(true);

    const additionalCondition = (() => {
      if (inputLabel === "Owner") {
        return where("owner", "==", true);
      } else if (inputLabel === "Tenant") {
        return where("tenant", "==", true);
      } else if (inputLabel === "Client") {
        return where("client", "==", true);
      } else if (inputLabel === "Filing representative") {
        return where("filingRep", "==", true);
      } else if (inputLabel === "Contractor") {
        return where("contractor", "==", true);
      }
      return null; 
    })();
  

   let conditions = [
      ...(userId && groupId && isPro && showAllDataFlag
        ? [or(where("groupId", "==", groupId), where("userId", "==", userId))]
        : userId
        ? [where("userId", "==", userId)]
        : []),
      // ...(additionalCondition ? [additionalCondition] : []),
    ];


    if(additionalCondition!=null){
      conditions = [
        ...(userId && groupId && isPro && showAllDataFlag
          ? [and(or(where("groupId", "==", groupId), where("userId", "==", userId)),additionalCondition) ]
          : userId
          ? [and(where("userId", "==", userId),additionalCondition)]
          : []),
      ];

    }

    


   let {
      data,
      lastVisible: newLastVisible,
      count,
    } = await fetchData({
      lastVisible:
        props?.lastVisible !== undefined ? props?.lastVisible : lastVisible,
      collectionName,
      searchText,
      label,
      // conditions:
      //   userId && groupId && isPro && showAllDataFlag
      //     ? [or(where("groupId", "==", groupId), where("userId", "==", userId))]
      //     : userId
      //       ? [where("userId", "==", userId)]
      //       : [],
      conditions
    });





    
////////////////////////////////////////////////////////////////////////////////
    const fetchObjectById = async (id) => {
      const docRef = doc(db, collectionName, id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return { id: docSnap.id, ...docSnap.data() };
      } else {
        console.error("No such document!");
        return null;
      }
    };
   
    
  
    if(value){
      const selectedObject = await fetchObjectById(value);

      if (selectedObject) {
    
        const isAlreadyPresent = data.some(item => item.id === selectedObject.id);
        if (!isAlreadyPresent) {
          data = [...data, selectedObject];
        }
      }
    }
  
////////////////////////////////////////////////////////////////////////////////




  // Function to fetch user data (owner/tenant) by ID
  const fetchUserFullNameById = async (userId) => {
    const docRef = doc(db, "contacts", userId); // Assuming "users" is the collection name for owner/tenant data
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data().fullName; // Assuming fullName is the field in the user document
    } else {
      return null;
    }
  };

  // Update data with owner and tenant full names
  const updatedData = await Promise.all(
    data.map(async (item) => {
      let ownerFullName = "";
      let tenantFullName = "";

      if (typeof item.owner === "string" && item.owner.trim() !== "") {
        ownerFullName = await fetchUserFullNameById(item.owner);
      }
      if (typeof item.tenant === "string" && item.tenant.trim() !== "") {
        tenantFullName = await fetchUserFullNameById(item.tenant);
      }

      return {
        ...item,
        ownerFullName,
        tenantFullName,
      };
    })
  );



    // if (searchText?.length > searchLimit) {
    //   setOptions(data);
    //   setAllDataLoaded(true);
    // } else
    //   setOptions((prevOptions) => [
    //     ...(props?.options || prevOptions),
    //     ...data.filter(
    //       (item) =>
    //         !prevOptions.some((prevItem) => prevItem[label] === item[label])
    //     ),
    //   ]);


    if (searchText?.length > searchLimit) {
      setOptions(updatedData);
      setAllDataLoaded(true);
    } else {
      setOptions((prevOptions) => [
        ...(props?.options || prevOptions),
        ...updatedData.filter(
          (item) => !prevOptions.some((prevItem) => prevItem[label] === item[label])
        ),
      ]);
    }


    setCount(count || 0);
    setLastVisible(newLastVisible);
    setLoading(false);
  };

  const handleOpen = () => {
    setOpen(true);
    if (options?.length >= count && options?.length !== 0) return;

    if (options.length === 0) {
      loadOptions();
    }
  };

  const handleScroll = (event) => {
    if (options?.length >= count) return;
    const bottom =
      event.target.scrollHeight - event.target.scrollTop ===
      event.target.clientHeight;
    if (bottom && !loading) {
      loadOptions();
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    if (searchTimeout !== null) clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      setSearchText(value);
    }, 500);
  };

  const searchCheck = searchText?.length > searchLimit;

  const optionsCollection = searchCheck ? filteredOptions : options;



// console.log(optionsCollection[0]?.[label])
//console.log(optionsCollection[0]?.businessName)
// console.log(optionsCollection)
// console.log(optionsCollection[0]?.owner)
// console.log(optionsCollection[1]?.tenant)



const infoTooltipContent = (
  <div>
    Make sure you select the appropriate contact type (under the Contacts page) so that it appears here.
  </div>
);



  return (
    <FormControl fullWidth>
      <InputLabel id={`infinite-select-label-${name}`}>{inputLabel}</InputLabel>
     
      <Select
        inputRef={selectElem}
        name={name}
        labelId={`infinite-select-label-${name}`}
        label={inputLabel}
        id="infinite-select"
        onOpen={handleOpen}
        onClose={() => {
          setOpen(false);
          setSearchText("");
        }}
        onChange={(...rest) =>
          onChange(
            ...rest,
            optionsCollection?.find(
              (item) => item?.id === rest?.[1]?.props?.value
            )
          )
        }
        value={value}
        MenuProps={{
          PaperProps: {
            onScroll: handleScroll,
            sx: {
              maxHeight: "300px",
            },
          },
          autoFocus: false,
        }}
        endAdornment={
          <>
            
            {(inputLabel === "Owner" ||
              inputLabel === "Tenant" ||
              inputLabel === "Client" ||
              inputLabel === "Filing representative" ||
              inputLabel === "Contractor") && (
              <InputAdornment position="end">
                <Tooltip title={infoTooltipContent} arrow>
                  <IconButton
                    sx={{
                      right: value?.length > 0 ? 0 : 10, 
                    }}
                  >
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            )}
        
          
            {value?.length > 0 && (
              <InputAdornment sx={{ marginRight: "10px" }} position="end">
                <IconButton
                  onClick={() => {
                    if (selectElem.current?.node) {
                      selectElem.current.node.value = "";
                      onChange({ target: selectElem.current.node });
                    }
                  }}
                >
                  <GridClearIcon />
                </IconButton>
              </InputAdornment>
            )}
          </>
        }
        
        
        // input={<TextField />}
        // onInput={(e) => console.log(e)}
      >
        <ListSubheader>
          <TextField
            size="small"
            // Autofocus on textfield
            autoFocus
            placeholder="Type to search..."
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
            onKeyDown={(e) => {
              if (e.key !== "Escape") {
                // Prevents autoselecting item while typing (default Select behaviour)
                e.stopPropagation();
              }
            }}
          />
        </ListSubheader>
        {/* {(optionsCollection || [])?.map((option) => (
          <MenuItem key={option.id} value={option.id} >
            <span dangerouslySetInnerHTML={{ __html: option?.[label] }}></span>
          </MenuItem>
        ))} */}

        {(optionsCollection || [])?.map((option) => {
            const labelValue = option?.[label] || ""; 

            //Add Buisness name beside the label of a contact
            const extraBuisnessLabelValue = option?.businessName ? `(${option?.businessName})` : "";

      


            // Construct the owner and tenant names if available
            const ownerFullName = option?.ownerFullName || "";
            const tenantFullName = option?.tenantFullName || "";
  
            const tooltipContent = (
              <>
                {ownerFullName && <div>Owner: {ownerFullName}</div>}
                {tenantFullName && <div>Tenant: {tenantFullName}</div>}
              </>
            );
  

            const displayValue = [labelValue, extraBuisnessLabelValue].filter(Boolean).join(' ');


          

  return (
    // <MenuItem key={option.id} value={option.id}>
    //   <span dangerouslySetInnerHTML={{ __html: displayValue }}></span>
    // </MenuItem>

  <MenuItem key={option.id} value={option.id}>
    {ownerFullName!=="" ||tenantFullName !=="" ? (
      <Tooltip title={tooltipContent} arrow>
        <span style={{ display: 'inline-block', width: '100%' }} dangerouslySetInnerHTML={{ __html: displayValue }}>
        </span>
      </Tooltip>
    ) : (
      <span style={{ display: 'inline-block', width: '100%' }} dangerouslySetInnerHTML={{ __html: displayValue }}>
      </span>
    )}

        
  </MenuItem>



  );
})}


        {loading && (
          <MenuItem disabled>
            <CircularProgress size={24} />
          </MenuItem>
        )}



      </Select>
    </FormControl>
  );
};

export default InfiniteScrollSelect;
