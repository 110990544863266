import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  styled,
  TextField,
  Paper,
  Grid,
  Box,
  Fab,
  MenuItem,
  Snackbar,
  IconButton,
   InputAdornment,
   Checkbox,
   FormControlLabel,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import ClearIcon from '@mui/icons-material/Clear';

import { SaveCloseDialog } from "../components/SaveCloseDialog";
import { Save, Close } from "@mui/icons-material";
import { db } from "config/config";
import { Context } from "context/Wrapper";
import { CreateUniqueDocument } from "components/shared/createUniqueDocument ";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Item = styled(Paper)(({ theme }) => {
  return {
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.primary.main,
    background: theme.palette.secondary.main,
  };
});

export const ContactNew = (props) => {
  const navigate = useNavigate();

  const context = useContext(Context);

  const { groupId } = context?.state?.userProfile || {};
  const user = context?.state?.userProfile || {};

  // const [contactDetail, setContactDetail] = React.useState({});
  const [contactDetail, setContactDetail] = React.useState({
    employee: false,
    client: false,
    owner: false,
    tenant: false,
    filingRep: false,
    architect: false,
    engineer: false,
    contractor: false,
  });





  const [open, setOpen] = React.useState(false);

  const [isDirty, setIsDirty] = React.useState(false);

  const [failureOpen, setOpenFailure] = React.useState(false);
  const [duplicateOpen, setduplicateOpen] = React.useState(false);
  const [SubmitLoader, setSubmitLoader] = React.useState(false);

  const [dialogOpen, setDialogOpen] = React.useState(false);

  const [addedSuccessfullyOpen,setAddedSuccessfullyOpen]=React.useState(false)


  const openAddedSuccessfully = () => {
    setAddedSuccessfullyOpen(true);
  };

  const handleCloseAddedSuccessfully = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAddedSuccessfullyOpen(false);
  };



  const openSuccess = () => {
    setOpen(true);
  };

  const openFailure = () => {
    setOpenFailure(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleFailureClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenFailure(false);
  };

  const handleduplicateOpen = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setduplicateOpen(false);
  };

  // const onSubmit = () => {
  //   db.collection("contacts")
  //     .add({
  //       ...contactDetail,
  //       userId: user.uid,
  //       groupId: groupId,
  //     })
  //     .then((docRef) => {
  //       navigate(`/contacts/details/${docRef.id}`);
  //     })
  //     .catch((error) => {
  //       console.error("Error adding document: ", error);
  //     });
  // };

  
  const onSubmit = (data) => {
    CreateUniqueDocument({
      openAddedSuccessfully,
      navigate:navigate,
      db:db,
      collectionName: "contacts",
      data: {
        ...contactDetail,
        userId: user.uid,
        groupId: groupId
      },
      uniqueFieldName: "fullName", // The field that should be unique

      failureCallback: () => {
        console.error("Error adding document.");
        setSubmitLoader(false)

      },
      duplicateCallback: () => {
        setduplicateOpen(true); // Handle duplicate case
        setSubmitLoader(false)

      },
      loadingCallback : ()=>{
        setSubmitLoader(true)
      }
    });
  };

  const saveAndClose = () => {
    onSubmit();
    navigate("/contacts");
  };

  const discardAndClose = () => {
    navigate("/contacts");
  };

  const handleChange = (e) => {
    let currentContactDetail = contactDetail;
    currentContactDetail[e.target.name] = e.target.value;
    setIsDirty(true);
    setContactDetail({ ...currentContactDetail });
  };

  const handleCloseContact = () => {
    if (isDirty) {
      setDialogOpen(true);
      return;
    }
    navigate("/contacts");
  };

  const hasRequiredFields = () => {
     return !!contactDetail.fullName;
  };



  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setContactDetail({
      ...contactDetail,
      [name]: checked ? true : false,
    });
  };




  return (
    <>
      <Typography
        sx={{ textTransform: "uppercase", marginBottom: "1rem" }}
        align="left"
        variant="h4"
      >
        New Contact
      </Typography>

      <form name="contactForm" id="contactForm">
        <Grid container spacing={2}>
          {/* <Grid item xs={12} md={6} lg={4}>
            <TextField
              label={"Salutation"}
              name="salutation"
              select
              onChange={handleChange}
              value={contactDetail.borough}
              fullWidth
            >
              <MenuItem value={"Mr"}>Mr</MenuItem>
              <MenuItem value={"Mrs"}>Mrs</MenuItem>
              <MenuItem value={"Ms"}>Ms</MenuItem>
              <MenuItem value={"Miss"}>Miss</MenuItem>
              <MenuItem value={"Mx"}>Mx</MenuItem>
              <MenuItem value={"Dr"}>Dr.</MenuItem>
              <MenuItem value={"Rev"}>Rev</MenuItem>
              <MenuItem value={"Rabbi"}>Rabbi</MenuItem>
            </TextField>
          </Grid> */}


<Grid container spacing={3} sx={{ marginTop: "10px" }}>
  {/* Left Side: Form Fields (2/3 of width) */}
  <Grid item xs={12} md={8}>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          label={"Salutation"}
          name="salutation"
          variant="outlined"
          select
          onChange={handleChange}
          value={contactDetail.salutation || ""}
          fullWidth
          InputProps={{
            endAdornment: contactDetail.salutation ? (
              <InputAdornment position="end">
                <IconButton
                  onClick={() =>
                    handleChange({ target: { name: "salutation", value: "" } })
                  }
                  style={{ marginRight: "10px" }}
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ) : null,
          }}
        >
          <MenuItem value={"Mr"}>Mr</MenuItem>
          <MenuItem value={"Mrs"}>Mrs</MenuItem>
          <MenuItem value={"Ms"}>Ms</MenuItem>
          <MenuItem value={"Miss"}>Miss</MenuItem>
          <MenuItem value={"Mx"}>Mx</MenuItem>
          <MenuItem value={"Dr"}>Dr.</MenuItem>
          <MenuItem value={"Rev"}>Rev</MenuItem>
          <MenuItem value={"Rabbi"}>Rabbi</MenuItem>
        </TextField>
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          label="Full Name"
          name="fullName"
          variant="outlined"
          value={contactDetail.fullName}
          onChange={handleChange}
          fullWidth
          required
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          label="First Name"
          name="firstName"
          variant="outlined"
          value={contactDetail.firstName}
          onChange={handleChange}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          label="MI"
          name="MI"
          variant="outlined"
          value={contactDetail.MI}
          onChange={handleChange}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          label="Last Name"
          name="lastName"
          variant="outlined"
          value={contactDetail.lastName}
          onChange={handleChange}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          label="DOB Login"
          name="dobLogin"
          variant="outlined"
          value={contactDetail.dobLogin}
          onChange={handleChange}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          label="Designation"
          name="designation"
          variant="outlined"
          select
          value={contactDetail.designation || ""}
          onChange={handleChange}
          fullWidth
          InputProps={{
            endAdornment: contactDetail.designation ? (
              <InputAdornment position="end">
                <IconButton
                  onClick={() =>
                    handleChange({
                      target: { name: "designation", value: "" },
                    })
                  }
                  style={{ marginRight: "10px" }}
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ) : null,
          }}
        >
          <MenuItem value="Owner">Owner</MenuItem>
          <MenuItem value="Registered Architect">Registered Architect</MenuItem>
          <MenuItem value="Professional Engineer">
            Professional Engineer
          </MenuItem>
          <MenuItem value="Special Inspection Agency">
            Special Inspection Agency
          </MenuItem>
        </TextField>
      </Grid>
    </Grid>
  </Grid>

  {/* Right Side: Contact Types Section (1/3 of width) */}
  <Grid item xs={12} md={4}>
    <Paper
      elevation={3}
      sx={{
        padding: "1.5rem",
        borderRadius: "8px",
        backgroundColor: "#f9f9f9",
        width: "100%",
          // margin: "5px auto",
          margin: {
            md: "15px auto", 
            lg: 0,           
          },
      }}
    >
      <Typography
        variant="h6"
        sx={{
          marginBottom: "1rem",
          fontWeight: "bold",
          textAlign: "center",
          color: "#333",
        }}
      >
        Contact Types
      </Typography>
      <Grid container spacing={2}>
        {[
          "employee",
          "client",
          "owner",
          "tenant",
          "filingRep",
          "architect",
          "engineer",
          "contractor",
        ].map((type) => (
          <Grid item xs={6} sm={6} key={type}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!contactDetail[type]}
                  onChange={handleCheckboxChange}
                  name={type}
                  size="small"
                />
              }
              label={type.charAt(0).toUpperCase() + type.slice(1)}
              sx={{
                fontSize: "0.85rem",
                "& .MuiTypography-root": {
                  fontSize: "0.95rem",
                  color: "#555",
                },
              }}
            />
          </Grid>
        ))}
      </Grid>
    </Paper>
  </Grid>
</Grid>


        </Grid>
        <Grid container sx={{ marginTop: "2rem" }} spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label="Business name"
              name="businessName"
              variant="outlined"
              value={contactDetail.businessName}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label="Address 1"
              name="address1"
              variant="outlined"
              value={contactDetail.address1}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label="Address 2"
              name="address2"
              variant="outlined"
              value={contactDetail.address2}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label="City"
              name="city"
              variant="outlined"
              value={contactDetail.city}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label="State"
              name="state"
              variant="outlined"
              value={contactDetail.state}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label="ZIP"
              name="ZIP"
              variant="outlined"
              value={contactDetail.ZIP}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container sx={{ marginTop: "2rem" }} spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label="Phone"
              name="phone"
              variant="outlined"
              value={contactDetail.phone}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label="Cell"
              name="mobile"
              variant="outlined"
              value={contactDetail.mobile}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label="Fax"
              name="fax"
              variant="outlined"
              value={contactDetail.fax}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label="Email"
              name="email"
              variant="outlined"
              value={contactDetail.email}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label="Website"
              name="website"
              variant="outlined"
              value={contactDetail.website}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container sx={{ marginTop: "2rem" }} spacing={2}>

          <Grid item xs={12} md={6} lg={4}>
           <TextField
        label="License type"
       name="licenseType"
       variant="outlined" 
      select
      value={contactDetail.licenseType || ""}
      onChange={handleChange}
      fullWidth
       InputProps={{
        endAdornment: contactDetail.licenseType ? ( 
        <InputAdornment position="end">
          <IconButton
            onClick={() => handleChange({ target: { name: 'licenseType', value: '' } })} 
            style={{ marginRight: '10px' }}
          >
            <ClearIcon />
          </IconButton>
        </InputAdornment>
         ) : null
       }}
      >
    <MenuItem value={"RA"}>RA</MenuItem>
    <MenuItem value={"PE"}>PE</MenuItem>
    <MenuItem value={"Filing rep"}>Filing rep</MenuItem>
    <MenuItem value={"Other"}>Other</MenuItem>
  </TextField>
</Grid>


          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label="License number"
              name="licenseNumber"
              variant="outlined"
              value={contactDetail.licenseNumber}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label="Filing rep registration number"
              name="frepRegistrationNumber"
              variant="outlined"
              value={contactDetail.frepRegistrationNumber}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label="Tax id"
              name="taxId"
              variant="outlined"
              value={contactDetail.taxId}
              onChange={handleChange}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label="HIC number"
              name="HICNumber"
              variant="outlined"
              value={contactDetail.HICNumber}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label="Insurance company"
              name="insuranceCompany"
              variant="outlined"
              value={contactDetail.insuranceCompany}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label="Policy number"
              name="policyNumber"
              variant="outlined"
              value={contactDetail.policyNumber}
              onChange={handleChange}
              fullWidth
            />
          </Grid>

        </Grid>
      </form>
      <SaveCloseDialog
        open={dialogOpen}
        saveAndClose={saveAndClose}
        discardAndClose={discardAndClose}
        setDialogOpen={setDialogOpen}
        isInactive={props.isInactive}
      />

      <Box
        sx={{
          position: "fixed",
          bottom: "1rem",
          right: "1rem",
          "& > :not(style)": { m: 1 },
        }}
      >
        {!props.isInactive && (
          <Fab
            color="primary"
            aria-label="add"
            variant="extended"
            onClick={onSubmit}
            form="contactForm"
            // disabled={(SubmitLoader || !isDirty && !hasRequiredFields()) || props.isInactive}
             disabled={(SubmitLoader || !isDirty) || props.isInactive || !hasRequiredFields()}
          >
            <Save sx={{ mr: 1 }} />
            {/* Save */}
            Add
          </Fab>
        )}
        <Fab
          color="secondary"
          aria-label="add"
          variant="extended"
          onClick={handleCloseContact}
        >
          <Close sx={{ mr: 1 }} />
          {/* Close */}
          Cancel
        </Fab>
      </Box>
      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Contact successfully saved!
        </Alert>
      </Snackbar>
      <Snackbar
        open={failureOpen}
        autoHideDuration={2000}
        onClose={handleFailureClose}
      >
        <Alert
          onClose={handleFailureClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          Something went wrong!
        </Alert>
      </Snackbar>

      <Snackbar
      open={duplicateOpen}
      autoHideDuration={2000}
      onClose={handleduplicateOpen}
    >
      <Alert
        onClose={handleduplicateOpen}
        severity="error"
        sx={{ width: "100%" }}
      >
        Contact full name already exists!
      </Alert>
    </Snackbar>
    <Snackbar
      open={addedSuccessfullyOpen}
      autoHideDuration={2000}
      onClose={handleCloseAddedSuccessfully}
    >
      <Alert
        onClose={handleCloseAddedSuccessfully}
        severity="success"
        sx={{ width: "100%" }}
      >
       Contact added successfully!
      </Alert>
    </Snackbar>
    </>
  );
};
