import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import { DataGrid, GridToolbar, GridActionsCellItem } from "@mui/x-data-grid";
import { Delete, Clear,ArrowForward  } from "@mui/icons-material";
import EditIcon from '@mui/icons-material/Edit';
import MuiAlert from "@mui/material/Alert";
import {
  Button,
  Modal,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Fab,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TablePagination,
  Snackbar,
  IconButton,
  InputAdornment
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from '@mui/icons-material/Clear';
import useCustomParams from "./shared/useCustomParams";
import InfiniteScrollSelect from "components/shared/InfiniteScrollSelect";
import ProjectStatus from "pages/ProjectStatus";


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const WorkTypes = ({ userId, projectId, contacts, isInactive,projectDOB }) => {
  const [workTypes, setWorkTypes] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [newWorkType, setNewWorkType] = useState("");
  const [estimatedCost, setEstimatedCost] = useState("");
  const [applicant, setApplicant] = useState("");
  const [filingRepresentative, setFilingRepresentative] = useState("");
  const [dateCreated, setDateCreated] = useState("");
  const [dobNumber, setDobNumber] = useState("");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [currentWorkType, setCurrentWorkType] = useState(null);
  const [openUpdateModal, setOpenUpdateModal] = useState(false)


const [displayProjectStatus,setDisplayProjectStatus]=useState({
  display:false,
 dobNumber:''
})



  const [feedbackMessage, setFeedbackMessage] = useState({
    open: false,
    message: '',
    severity: 'success'
  });


  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection("workTypes")
      .where("userId", "==", userId)
      .where("projectId", "==", projectId)
      .onSnapshot((snapshot) => {
        const workTypesData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setWorkTypes(workTypesData);
      });

    return () => {
      unsubscribe();
    };
  }, [userId, projectId]);






  const handleOpenModal = () => {
    setNewWorkType("");
    setApplicant("");
    setFilingRepresentative("");
    setDateCreated("");
    setDobNumber("");
    setDateCreated(new Date().toISOString().split("T")[0]);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleAddWorkType = async () => {
    try {
      await firebase.firestore().collection("workTypes").add({
        workType: newWorkType,
        estimatedCost,
        applicant,
        filingRepresentative,
        dateCreated,
        dobNumber,
        userId,
        projectId,
      });
      setNewWorkType("");
      setEstimatedCost("");
      setApplicant("");
      setFilingRepresentative("");
      setDateCreated("");
      setDobNumber("");
      handleCloseModal();
      openFeedbackMessage('Work type added successfully!', 'success');
    } catch (error) {
      console.error("Error adding work type:", error);
    }
  };

  const handleUpdateWorkType = async () => {
    try {
      await firebase.firestore().collection("workTypes").doc(currentWorkType.id).update(currentWorkType);
      handleCloseUpdateModal(); // Close modal after update
      openFeedbackMessage('Work type updated successfully!', 'success');
    } catch (error) {
      console.error("Error updating work type:", error);
    }
  };


  const handleCloseUpdateModal = () => {
    setOpenUpdateModal(false)
  }


  const editDocument = (id) => {
    const workTypeToEdit = workTypes.find((workType) => workType.id === id);
    if (workTypeToEdit) {
      setCurrentWorkType(workTypeToEdit);
      // setNewWorkType(workTypeToEdit.workType);
      // setApplicant(workTypeToEdit.applicant);
      // setFilingRepresentative(workTypeToEdit.filingRepresentative);
      // setDateCreated(workTypeToEdit.dateCreated);
      // setDobNumber(workTypeToEdit.dobNumber);
      setOpenUpdateModal(true); // Open modal for editing
    }
  };




  const handleDeleteWorkType = async () => {
    try {
      await firebase.firestore().collection("workTypes").doc(deleteId).delete();
      setOpenConfirmDialog(false);
      setDeleteId(null);
      openFeedbackMessage('Work type deleted successfully!', 'success');
    } catch (error) {
      console.error("Error deleting work type:", error);
    }
  };

  const confirmDeleteWorkType = (id) => {
    setDeleteId(id);
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
    setDeleteId(null);
  };


  const openFeedbackMessage = (message, severity) => {
    setFeedbackMessage({ open: true, message, severity });
  };

  const handleCloseFeedbackMessage = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedbackMessage({ ...feedbackMessage, open: false });
  };



  const columns = [
    { field: "workType", headerName: "Work Type", width: 150 },
    { field: "estimatedCost", headerName: "Estimated Cost", width: 150 },
    { field: "applicantName", headerName: "Applicant", width: 150 },
    {
      field: "filingRep",
      headerName: "Filing Representative",
      width: 200,
    },
    { field: "dateCreated", headerName: "Date Filed", width: 150 },
    { field: "dobNumber", headerName: "DOB Number", width: 150 },
  ];

  if (!isInactive) {
    columns.push({
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <>
          <GridActionsCellItem
            icon={<EditIcon />}
            onClick={() => editDocument(params.row.id)}
            label="Edit"
          />
          <GridActionsCellItem
            icon={<Delete />}
            onClick={(event) => {
              event.stopPropagation(); // Prevent row click event
              confirmDeleteWorkType(params.row.id);
            }}
            label="Delete"
          />
          {(params.row.dobNumber!=='' && params.row.dobNumber!==undefined && !isInactive) && 
      <GridActionsCellItem
      icon={<ArrowForward />}
       onClick={(event) => {
      event.stopPropagation(); // Prevent row click event
      openProjectStatus(params.row);
      }}
       label="Delete"
       />
          }
         

        </>
      ),
    });
  }





  const rows = workTypes.map((workType, index) => ({
    id: workType.id, // Assuming each workType object has an 'id' property
    workType: workType.workType,
    estimatedCost: workType.estimatedCost,
    applicantName:
      contacts.find((contact) => contact.id === workType.applicant)?.fullName ||
      "",
    filingRepresentativeName:
      contacts.find((contact) => contact.id === workType.filingRepresentative)
        ?.fullName || "",
    dateCreated: workType.dateCreated,
    dobNumber: workType.dobNumber,
    // Add more properties as needed
  }));

  // const [paginationModel, setPaginationModel] = useState({
  //   pageSize: 50,
  //   page: 0,
  // });

  const { params, updateParams } = useCustomParams();

  const pageSize =
    Number(params.get("pageSize")) || 50,
    page = Number(params.get("page")) || 0;




const openProjectStatus=(params)=>{
setDisplayProjectStatus({
  display:true,
  dobNumber:params.dobNumber
})
}

const handleCloseFromWorkTypeDobTable=()=>{
  setDisplayProjectStatus((prev)=>{
    return {
    ...prev,
      display:false
  }
  })
}


if(displayProjectStatus.display){
  return (<div style={{ height: "calc(100vh - 230px)" }}>
  <ProjectStatus
  //  thisProject={projectDOB}
    workTypeIDs={[displayProjectStatus.dobNumber]}
    fromWorkType={true}
    handleCloseFromWorkType={handleCloseFromWorkTypeDobTable}
  />
</div>)
}

  return (
    <div style={{ height: 400, width: "100%" }}>

      
      <DataGrid rows={rows} columns={columns} pageSizeOptions={[10, 25, 50, 100]}
        pagination
        hideFooter
        paginationModel={{ pageSize, page }}
        onRowClick={(params) => editDocument(params.row.id)}
        onPaginationModelChange={(params) => {
          if (workTypes.length > 0)
            updateParams({
              page: params.page,
              pageSize: params.pageSize,
            });
        }}
        rowCount={workTypes.length}
        initialState={{
          pagination: { paginationModel: { pageSize: 50 } },
        }}
        onFilterModelChange={(e) => {
          updateParams({
            pageSize: false,
            page: false,
            search: e?.items?.map((item) => item?.value).join("+"),
          });
        }}
        slots={{
          toolbar: GridToolbar,
        }} />

      {workTypes.length > 0 && (
        <TablePagination
          component="div"
          count={workTypes.length}
          page={page}
          onPageChange={(_, e) => {
            updateParams({
              page: e,
              pageIncreasing: `${e > Number(params.get("page"))}`,
            });
          }}
          rowsPerPageOptions={[10, 25, 50, 100]}
          rowsPerPage={pageSize}
          onRowsPerPageChange={(e) => {
            updateParams({ pageSize: `${e.target.value}`, page: false });
          }}
        />
      )}

      <Box
        sx={{
          position: "fixed",
          bottom: "1rem",
          left: "4rem",
          "& > :not(style)": { m: 1 },
        }}
      >
        {!isInactive && (
          <Fab
            color="primary"
            aria-label="add"
            variant="extended"
            onClick={handleOpenModal}
          >
            <AddIcon sx={{ mr: 1 }} />
            New
          </Fab>
        )}
      </Box>
      <Modal open={openModal} onClose={handleCloseModal}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            backgroundColor: "white",
            padding: 20,
          }}
        >
          <h2>Add New Work Type</h2>

          {/* <FormControl fullWidth>
            <InputLabel>Work Type</InputLabel>
            <Select
              value={newWorkType}
              required
              onChange={(e) => setNewWorkType(e.target.value)}
              sx={{ marginBottom: "1rem" }}
            >
              <MenuItem value="PL">PL (Plumbing)</MenuItem>
              <MenuItem value="AT">AT (Architectural)</MenuItem>
              <MenuItem value="EL">EL (Electrical)</MenuItem>
              <MenuItem value="OT">OT (Other)</MenuItem>
              <MenuItem value="MH">MH (Mechanical)</MenuItem>
              <MenuItem value="SP">SP (Sprinkler)</MenuItem>
              <MenuItem value="FA">FA (Fire Alarm)</MenuItem>
            </Select>
          </FormControl> */}

          <FormControl fullWidth>
            <TextField
              label="Work Type"
              name="workType"
              variant="outlined"
              select
              value={newWorkType || ""}
              onChange={(e) => setNewWorkType(e.target.value)}
              sx={{ marginBottom: "1rem" }}
              InputProps={{
                endAdornment: newWorkType ? (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setNewWorkType("")}
                      style={{ marginRight: '10px' }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ) : null
              }}
            >
              <MenuItem value="AN">AN (Antenna)</MenuItem>
              <MenuItem value="AT">AT (Architectural)</MenuItem>
              <MenuItem value="BE">BE (Boiler Equipment)</MenuItem>
              <MenuItem value="FN">FN (Construction Fence)</MenuItem>
              <MenuItem value="CC">CC (Curb Cut)</MenuItem>
              <MenuItem value="EA">EA (Earthwork)</MenuItem>

              <MenuItem value="EL">EL (Electrical)</MenuItem>
              <MenuItem value="FA">FA (Fire Alarm)</MenuItem>
              <MenuItem value="FO">FO (Foundation)</MenuItem>
              <MenuItem value="GC">GC (General Construction)</MenuItem>
              <MenuItem value="GC-CX">GC-CX (Combined (GC + others))</MenuItem>
              <MenuItem value="LA">LA (Limited Alteration Applications (LAA))</MenuItem>

              <MenuItem value="MS">MS (Mechanical Systems)</MenuItem>
              <MenuItem value="MH">MH (Mechanical/HVAC)</MenuItem>
              <MenuItem value="OT">OT (Other)</MenuItem>
              <MenuItem value="PA">PA (Place of Assembly)</MenuItem>
              <MenuItem value="PL">PL (Plumbing)</MenuItem>
              <MenuItem value="PM">PM (Protection and Mechanical Methods)</MenuItem>

              <MenuItem value="SP">SP (Sprinkler)</MenuItem>
              <MenuItem value="SD">SD (Standpipe)</MenuItem>
              <MenuItem value="SE">SE (Support of Excavation)</MenuItem>
              <MenuItem value="SF">SF (Supported Scaffold)</MenuItem>
              <MenuItem value="SG">SG (Sign)</MenuItem>

              <MenuItem value="SH">SH (Sidewalk Shed)</MenuItem>
              <MenuItem value="ST">ST (Structural)</MenuItem>
              <MenuItem value="TA">TA (Temporary Place of Assembly)</MenuItem>
              <MenuItem value="VT">VT (Elevator)</MenuItem>

            </TextField>
          </FormControl>

          <TextField
            label="Estimated Cost"
            type="number"
            value={estimatedCost}
            onChange={(e) => setEstimatedCost(e.target.value)}
            fullWidth
            sx={{ marginBottom: "1rem" }}
          />



          {/* <TextField
            label="Applicant"
            value={applicant}
            onChange={(e) => setApplicant(e.target.value)}
            fullWidth
            select
            sx={{ marginBottom: "1rem" }}
          >
            {contacts.map((contact) => (
              <MenuItem key={contact.id} value={contact.id}>
                {contact.fullName}
              </MenuItem>
            ))}
          </TextField> */}

          <FormControl fullWidth sx={{ marginBottom: "1rem" }}>
            <InfiniteScrollSelect
              collectionName="contacts"
              label="fullName"
              onChange={(e, selected) => setApplicant(e.target.value)} // Store the entire selected object
              value={applicant}
              name="applicant"
              inputLabel="Applicant"
            />

          </FormControl>

          {/* <TextField
            label="Filing Representative"
            value={filingRepresentative}
            onChange={(e) => setFilingRepresentative(e.target.value)}
            fullWidth
            select
            sx={{ marginBottom: "1rem" }}
          >
            {contacts.map((contact) => (
              <MenuItem key={contact.id} value={contact.id}>
                {contact.fullName}
              </MenuItem>
            ))}
          </TextField> */}

          <FormControl fullWidth sx={{ marginBottom: "1rem" }}>
            <InfiniteScrollSelect
              collectionName="contacts"
              label="fullName"
              onChange={(e, selected) => setFilingRepresentative(e.target.value)} // Store the entire selected object
              value={filingRepresentative}
              // name="filingRepresentative"
                name="filingRep"
              inputLabel="Filing representative"
            />
          </FormControl>

          <TextField
            label="Date Filed"
            value={dateCreated}
            onChange={(e) => setDateCreated(e.target.value)}
            fullWidth
            type="date"
            defaultValue={new Date()}
            sx={{ marginBottom: "1rem" }}
          />
          <TextField
            label="DOB Number"
            // required
            value={dobNumber}
            onChange={(e) => setDobNumber(e.target.value)}
            fullWidth
            sx={{ marginBottom: "1rem" }}
          />
          {/* <Button
            variant="contained"
            color="primary"
            disabled={!newWorkType || !dateCreated || !dobNumber}
            onClick={handleAddWorkType}
            style={{ marginTop: 20 }}
          >
            Add
          </Button> */}
          <Button
            variant="contained"
            color="primary"
            disabled={!newWorkType || !dateCreated}
            onClick={handleAddWorkType}
            style={{ marginTop: 20 }}
          >
            Add
          </Button>

          <Button
            variant="contained"
            color="secondary"
            onClick={handleCloseModal}
            style={{ marginTop: 20 }}
            sx={{ marginLeft: "1rem" }}
          >
            Cancel
          </Button>
        </div>
      </Modal>







      <Modal open={openUpdateModal} onClose={handleCloseUpdateModal}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            backgroundColor: "white",
            padding: 20,
          }}
        >
          <h2>Update Work Type</h2>

          {/* <FormControl fullWidth>
      <InputLabel>Work Type</InputLabel>
      <Select
        value={currentWorkType?.workType}
        required
        onChange={(e) => setCurrentWorkType((prev)=>({...prev,workType:e.target.value}))}
        sx={{ marginBottom: "1rem" }}
      >
        <MenuItem value="PL">PL (Plumbing)</MenuItem>
        <MenuItem value="AT">AT (Architectural)</MenuItem>
        <MenuItem value="EL">EL (Electrical)</MenuItem>
        <MenuItem value="OT">OT (Other)</MenuItem>
        <MenuItem value="MH">MH (Mechanical)</MenuItem>
      </Select>
    </FormControl> */}

          <FormControl fullWidth>
            <TextField
              label="Work Type"
              name="workType"
              variant="outlined"
              select
              value={currentWorkType?.workType || ""}
              onChange={(e) => setCurrentWorkType((prev) => ({ ...prev, workType: e.target.value }))}
              sx={{ marginBottom: "1rem" }}
              InputProps={{
                endAdornment: currentWorkType?.workType ? (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setCurrentWorkType((prev) => ({ ...prev, workType: "" }))}
                      style={{ marginRight: '10px' }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ) : null
              }}
            >
              <MenuItem value="AN">AN (Antenna)</MenuItem>
              <MenuItem value="AT">AT (Architectural)</MenuItem>
              <MenuItem value="BE">BE (Boiler Equipment)</MenuItem>
              <MenuItem value="FN">FN (Construction Fence)</MenuItem>
              <MenuItem value="CC">CC (Curb Cut)</MenuItem>
              <MenuItem value="EA">EA (Earthwork)</MenuItem>

              <MenuItem value="EL">EL (Electrical)</MenuItem>
              <MenuItem value="FA">FA (Fire Alarm)</MenuItem>
              <MenuItem value="FO">FO (Foundation)</MenuItem>
              <MenuItem value="GC">GC (General Construction)</MenuItem>
              <MenuItem value="GC-CX">GC-CX (Combined (GC + others))</MenuItem>
              <MenuItem value="LA">LA (Limited Alteration Applications (LAA))</MenuItem>

              <MenuItem value="MS">MS (Mechanical Systems)</MenuItem>
              <MenuItem value="MH">MH (Mechanical/HVAC)</MenuItem>
              <MenuItem value="OT">OT (Other)</MenuItem>
              <MenuItem value="PA">PA (Place of Assembly)</MenuItem>
              <MenuItem value="PL">PL (Plumbing)</MenuItem>
              <MenuItem value="PM">PM (Protection and Mechanical Methods)</MenuItem>

              <MenuItem value="SP">SP (Sprinkler)</MenuItem>
              <MenuItem value="SD">SD (Standpipe)</MenuItem>
              <MenuItem value="SE">SE (Support of Excavation)</MenuItem>
              <MenuItem value="SF">SF (Supported Scaffold)</MenuItem>
              <MenuItem value="SG">SG (Sign)</MenuItem>

              <MenuItem value="SH">SH (Sidewalk Shed)</MenuItem>
              <MenuItem value="ST">ST (Structural)</MenuItem>
              <MenuItem value="TA">TA (Temporary Place of Assembly)</MenuItem>
              <MenuItem value="VT">VT (Elevator)</MenuItem>

            </TextField>
          </FormControl>


          <TextField
            label="Estimated Cost"
            type="number"
            value={currentWorkType?.estimatedCost}
            onChange={(e) => setCurrentWorkType((prev) => ({ ...prev, estimatedCost: e.target.value }))}
            fullWidth
            sx={{ marginBottom: "1rem" }}
          />



          {/* <TextField
      label="Applicant"
      value={currentWorkType?.applicant}
      onChange={(e) => setCurrentWorkType((prev)=>({...prev,applicant:e.target.value}))}
      fullWidth
      select
      sx={{ marginBottom: "1rem" }}
    >
      {contacts.map((contact) => (
        <MenuItem key={contact.id} value={contact.id}>
          {contact.fullName}
        </MenuItem>
      ))}
    </TextField> */}

          <FormControl fullWidth sx={{ marginBottom: "1rem" }}>
            <InfiniteScrollSelect
              collectionName="contacts"
              label="fullName"
              onChange={(e, selected) => setCurrentWorkType((prev) => ({ ...prev, applicant: e.target.value }))} // Store the entire selected object
              value={currentWorkType?.applicant}
              name="applicant"
              inputLabel="Applicant"
            />

          </FormControl>


          {/* <TextField
      label="Filing Representative"
      value={currentWorkType?.filingRepresentative}
      onChange={(e) => setCurrentWorkType((prev)=>({...prev,filingRepresentative:e.target.value}))}
      fullWidth
      select
      sx={{ marginBottom: "1rem" }}
    >
      {contacts.map((contact) => (
        <MenuItem key={contact.id} value={contact.id}>
          {contact.fullName}
        </MenuItem>
      ))}
    </TextField> */}

          <FormControl fullWidth sx={{ marginBottom: "1rem" }}>
            <InfiniteScrollSelect
              collectionName="contacts"
              label="fullName"
              onChange={(e, selected) => setCurrentWorkType((prev) => ({ ...prev, filingRepresentative: e.target.value }))} // Store the entire selected object
              value={currentWorkType?.filingRepresentative}
             name="filingRep"
              inputLabel="Filing representative"
            />

          </FormControl>

          <TextField
            label="Date Filed"
            value={currentWorkType?.dateCreated}
            onChange={(e) => setCurrentWorkType((prev) => ({ ...prev, dateCreated: e.target.value }))}
            fullWidth
            type="date"
            sx={{ marginBottom: "1rem" }}
          />

          <TextField
            label="DOB Number"
            // required
            value={currentWorkType?.dobNumber}
            onChange={(e) => setCurrentWorkType((prev) => ({ ...prev, dobNumber: e.target.value }))}
            fullWidth
            sx={{ marginBottom: "1rem" }}
          />

          {!isInactive &&
            <Button
              variant="contained"
              color="primary"
              disabled={!currentWorkType?.workType || !currentWorkType?.dateCreated}
              onClick={handleUpdateWorkType} // Ensure this function is defined for updating
              style={{ marginTop: 20 }}
            >
              Save
            </Button>
          }

          <Button
            variant="contained"
            color="secondary"
            onClick={handleCloseUpdateModal}
            style={{ marginTop: 20 }}
            sx={{ marginLeft: "1rem" }}
          >
            Close
          </Button>
        </div>
      </Modal>



      <Dialog open={openConfirmDialog} onClose={handleCloseConfirmDialog}>
        <DialogTitle>Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this work type? This action cannot
            be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteWorkType} variant="contained" >
            Delete
          </Button>
          <Button onClick={handleCloseConfirmDialog} variant="contained" color="secondary" >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>


      <Snackbar
        open={feedbackMessage.open}
        autoHideDuration={2000}
        onClose={handleCloseFeedbackMessage}
      >
        <Alert
          onClose={handleCloseFeedbackMessage}
          severity={feedbackMessage.severity}
          sx={{ width: "100%" }}
        >
          {feedbackMessage.message}
        </Alert>
      </Snackbar>


    </div>
  );
};
