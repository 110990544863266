import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Typography,
  TextField,
  Grid,
  Box,
  Tab,
  Tabs,
  Fab,
  MenuItem,
  Snackbar,
  IconButton,
  InputAdornment
} from "@mui/material";
import { useSubscriptionState } from "../context/SubscriptionContext";

import MuiAlert from "@mui/material/Alert";


import Status from "./Status";
import { Forms } from "../components/Forms";
import Notes from "../components/Notes";

import { SaveCloseDialog } from "../components/SaveCloseDialog";
import { Save, Close } from "@mui/icons-material";
import ClearIcon from '@mui/icons-material/Clear';

import { doc, getDoc, setDoc, where, query, collection, getDocs } from "firebase/firestore";
import { db } from "config/config";
import { Context } from "context/Wrapper";
import InfiniteScrollSelect from "components/shared/InfiniteScrollSelect";
import { Projects } from "./Projects";
import { useFetchCollectionDocs } from "apis/dataSnapshots";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, pl: 0, pr: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export const BuildingDetail = (props) => {
  const context = useContext(Context);

  const { groupId, isPro, userId } = context?.state?.userProfile || {};
  const user = context?.state?.userProfile || {},
    { showAllDataFlag } = context?.state || {};

  const { buildingId } = useParams();
    const location=useLocation()

  const currentSubscription = useSubscriptionState();

  // const contacts = useFetchCollectionDocs({
  //   collectionName: "contacts",
  //   conditions:
  //     userId && groupId && isPro && showAllDataFlag
  //       ? [or(where("groupId", "==", groupId), where("userId", "==", userId))]
  //       : userId
  //         ? [where("userId", "==", userId)]
  //         : [],
  // });

  // const { groupId } = props;

  const navigate = useNavigate();

  const [buildingDetail, setBuildingDetail] = useState();

  const [ownerDetails, setOwnerDetails] = React.useState(null);






  useEffect(() => {
    async function getBuildings() {
      const buildingRef = doc(db, "buildings", buildingId);
      const initialBuildingDetail = await getDoc(buildingRef);
      setBuildingDetail(initialBuildingDetail.data());
    }
    getBuildings();

  }, [buildingId]);

  const [isDirty, setIsDirty] = React.useState(false);

  const [tab, setTab] = React.useState(location.state?.FromJobDetailsReferencesBuilding ? 1:0);

  const [open, setOpen] = React.useState(false);

  const [dialogOpen, setDialogOpen] = React.useState(false);

  const [failureOpen, setOpenFailure] = React.useState(false);
  const [duplicateOpen, setduplicateOpen] = React.useState(false);
  const [SubmitLoader, setSubmitLoader] = React.useState(false);


  const openSuccess = () => {
    setOpen(true);
  };

  const openFailure = () => {
    setOpenFailure(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleFailureClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenFailure(false);
  };

  const handleduplicateOpen = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setduplicateOpen(false);
  };

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const handleChange = (e) => {
    let currentBuildingDetail = buildingDetail;
    currentBuildingDetail[e.target.name] = e.target.value;
    setIsDirty(true);
    setBuildingDetail({ ...currentBuildingDetail });
  };

  const handleChangeNotes = (notes) => {
    let currentBuildingDetail = buildingDetail;
    setBuildingDetail({ ...currentBuildingDetail, notes: notes });
  };


//   const onSubmit = async() => {
//     setSubmitLoader(true)
//     // Normalize the customerReference for comparison
//     const normalizedCustomerReference = buildingDetail.customerReference.trim().toLowerCase();
//     const thisBuilding = doc(db, "buildings", buildingId);

//     // Check if any other building has the same customerReference (case-insensitive)
//    await db.collection("buildings")
//       .get()
//       .then((querySnapshot) => {
//         // Check if there's any other document with the same customerReference but different ID
//         const isDuplicate = querySnapshot.docs.some(doc => {
//           const docData = doc.data();
//           const docCustomerReference = docData.customerReference ? docData.customerReference.trim().toLowerCase() : '';
//           return doc.id !== buildingId && docCustomerReference === normalizedCustomerReference;
//         });

//         if (!isDuplicate) {
//           // If no duplicates, proceed to update the document with the original input
//           setDoc(thisBuilding, {
//             ...buildingDetail,
//             customerReference: buildingDetail.customerReference.trim(), // Store original case-sensitive version
//           }, { merge: true })
//             .then(() => {
//               setIsDirty(false);
//               openSuccess();
//               setSubmitLoader(false)
//             })
//             .catch((error) => {
//               console.error("Error updating document: ", error);
//               openFailure();
//               setSubmitLoader(false)
//             });
//         } else {
//           // If a matching document is found, display an error
//           setduplicateOpen(true);
//           setSubmitLoader(false)
//         }
//       })
//       .catch((error) => {
//         console.error("Error checking for unique customerReference: ", error);
//         setSubmitLoader(false)
//       });
// };

const onSubmit = async () => {
  setSubmitLoader(true);
  const normalizedCustomerReference = buildingDetail.customerReference.trim().toLowerCase();
  const thisBuilding = doc(db, "buildings", buildingId);

  try {
    const q = query(collection(db, "buildings"), where("groupId", "==", groupId));
    const querySnapshot = await getDocs(q);
    const isDuplicate = querySnapshot.docs.some(doc => {
      const docData = doc.data();
      const docCustomerReference = docData.customerReference ? docData.customerReference.trim().toLowerCase() : '';
      return doc.id !== buildingId && docCustomerReference === normalizedCustomerReference;
    });

    if (!isDuplicate) {
      await setDoc(thisBuilding, {
        ...buildingDetail,
        customerReference: buildingDetail.customerReference.trim(),
      }, { merge: true });
      
      setIsDirty(false);
      openSuccess();
      return true; // Indicate success
    } else {
      setduplicateOpen(true);
      return false; // Indicate duplicate
    }
  } catch (error) {
    console.error("Error updating document:", error);
    openFailure();
    return false; // Indicate failure
  } finally {
    setSubmitLoader(false);
  }
};

const saveAndClose = async () => {
  const isSuccess = await onSubmit();
  
  if (isSuccess) {
    navigate("/buildings", { state: { feedback: { message: 'Building saved successfully!', severity: 'success' } } });
  } else {
    setDialogOpen(false); 
  }
};


  const discardAndClose = () => {
    navigate("/buildings");
  };

  const handleCloseBuilding = () => {
    if (isDirty && !props.isInactive) {
      setDialogOpen(true);
      return;
    }
    navigate("/buildings");
  };

  React.useEffect(() => {
    if (buildingDetail && buildingDetail.owner) {
      const fetchOwnerDetails = async () => {
        const ownerRef = doc(db, "contacts", buildingDetail.owner);
        const initialOwnerDetail = await getDoc(ownerRef);
        const owner = initialOwnerDetail.data();
        setOwnerDetails(owner);
      };
      fetchOwnerDetails();
    }
  }, [buildingDetail]);

  if (!buildingDetail) return null;

  const hasRequiredFields = () => {
    return (
      buildingDetail.customerReference &&
      buildingDetail.houseNumber &&
      buildingDetail.streetName
    );
  };

  return (
    <>
      <Typography
        sx={{
          textTransform: "uppercase",
          /*    backgroundColor: "#f89d08",
          padding: "1rem", */
        }}
        align="left"
        variant="h4"
      >
        {buildingDetail.customerReference}
      </Typography>
      <Box sx={{ borderBottom: 1, borderColor: "divider", paddingLeft: 0 }}>
        <Tabs
          value={tab}
          onChange={handleChangeTab}
          aria-label="basic tabs example"
        >
          <Tab label="Details" />
          <Tab label="Jobs" />
          <Tab label="DOB status" />
          <Tab label="Notes" />
          <Tab label="Forms" />
         
        </Tabs>
      </Box>
      <TabPanel value={tab} index={0}>
        <form name="buildingForm" id="buildingForm">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="Name"
                variant="outlined"
                name="customerReference"
                value={buildingDetail.customerReference}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
        <TextField
       label={"Borough"}
       name="borough"
       select
       onChange={handleChange}
      value={buildingDetail.borough || ""} 
      fullWidth
       InputProps={{
      endAdornment: buildingDetail.borough ? (
        <InputAdornment position="end">
          <IconButton
            onClick={() => handleChange({ target: { name: 'borough', value: '' } })} 
            style={{ marginRight: '10px' }} 
          >
            <ClearIcon />
          </IconButton>
        </InputAdornment>
      ) : null
    }}
  >
    <MenuItem value={"Brooklyn"}>Brooklyn</MenuItem>
    <MenuItem value={"Manhattan"}>Manhattan</MenuItem>
    <MenuItem value={"Bronx"}>Bronx</MenuItem>
    <MenuItem value={"Queens"}>Queens</MenuItem>
    <MenuItem value={"Staten Island"}>Staten Island</MenuItem>
  </TextField>
</Grid>



            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="House number"
                variant="outlined"
                name="houseNumber"
                value={buildingDetail.houseNumber}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="Street name"
                variant="outlined"
                name="streetName"
                value={buildingDetail.streetName}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="City"
                variant="outlined"
                name="city"
                value={buildingDetail.city}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="State"
                variant="outlined"
                name="state"
                value={buildingDetail.state}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="ZIP"
                variant="outlined"
                name="ZIP"
                value={buildingDetail.ZIP}
                onChange={handleChange}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="BIN"
                variant="outlined"
                name="BIN"
                value={buildingDetail.BIN}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="Block"
                variant="outlined"
                name="block"
                value={buildingDetail.block}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="Lot"
                variant="outlined"
                name="lot"
                value={buildingDetail.lot}
                onChange={handleChange}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="Apt./Condo No(s)"
                variant="outlined"
                name="apt"
                value={buildingDetail.apt}
                onChange={handleChange}
                fullWidth

              />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="CB No."
                variant="outlined"
                name="cb"
                value={buildingDetail.cb}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              {/* <TextField
                label={"Owner"}
                name="owner"
                select
                onChange={handleChange}
                value={buildingDetail.owner}
                fullWidth
              >
                {contacts.map((contact) => (
                  <MenuItem value={contact.id}>{contact.fullName}</MenuItem>
                ))}
              </TextField> */}
              <InfiniteScrollSelect
                collectionName="contacts"
                label="fullName"
                onChange={handleChange}
                value={buildingDetail.owner}
                name="owner"
                inputLabel="Owner"
              />
            </Grid>

          </Grid>
        </form>
        <Box
          sx={{
            position: "fixed",
            bottom: "1rem",
            right: "1rem",
            "& > :not(style)": { m: 1 },
          }}
        >
          {!props.isInactive && (
            <Fab
              color="primary"
              aria-label="add"
              variant="extended"
              onClick={onSubmit}
              disabled={SubmitLoader || (!isDirty && !hasRequiredFields()) || props.isInactive}
            >
              <Save sx={{ mr: 1 }} />
              Save
            </Fab>
          )}
         
          <Fab
            color="secondary"
            aria-label="add"
            variant="extended"
            onClick={handleCloseBuilding}
          >
            <Close sx={{ mr: 1 }} />
            Close
          </Fab>
             
        </Box>

        <SaveCloseDialog
          open={dialogOpen}
          saveAndClose={saveAndClose}
          discardAndClose={discardAndClose}
          setDialogOpen={setDialogOpen}
          isInactive={props.isInactive}
        />
      </TabPanel>
      
      <TabPanel value={tab} index={1}>
      <Projects isInactive={currentSubscription.isInactive} fromBuildingReferencesJobDetails={{buildingId,fromBuildingReferencesJob:true}}/>
      </TabPanel>

      <TabPanel value={tab} index={2}>
        {!props.isInactive ? (
          <div style={{ height: "calc(100vh - 230px)" }}>
            <Status currentBuilding={{ ...buildingDetail }} />
          </div>
        ) : (
          <div style={{ height: "calc(100vh - 230px)" }}>
            This feature is available with an active subscription.
          </div>
        )}
      </TabPanel>
      
      
      <TabPanel value={tab} index={3}>
        <Notes
          notes={buildingDetail.notes}
          handleChangeNotes={handleChangeNotes}
          onSubmit={onSubmit}
          isInactive={props.isInactive}
        />
      </TabPanel>

      <TabPanel value={tab} index={4}  style={{ marginBottom: "160px" }}>
        <Forms
        // showLabelBuildingForms={true}
        showToggleFromBuilding={true}
          buildingId={buildingId}
          buildingDetails={buildingDetail}
          ownerDetails={ownerDetails}
          userId={buildingDetail.userId}
          db={db}
          groupId={groupId}
          isInactive={props.isInactive}
        />
      </TabPanel>

      {/* <TabPanel value={tab} index={4}>
        <Forms
        showLabelBuildingJobsForms={true}
          showJobBuildingForms={true}
          buildingId={buildingId}
          buildingDetails={buildingDetail}
          ownerDetails={ownerDetails}
          userId={buildingDetail.userId}
          db={db}
          groupId={groupId}
          isInactive={props.isInactive}
        />
      </TabPanel> */}

     
      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Building saved successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={failureOpen}
        autoHideDuration={2000}
        onClose={handleFailureClose}
      >
        <Alert
          onClose={handleFailureClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          Something went wrong!
        </Alert>
      </Snackbar>

      <Snackbar
      open={duplicateOpen}
      autoHideDuration={2000}
      onClose={handleduplicateOpen}
    >
      <Alert
        onClose={handleduplicateOpen}
        severity="error"
        sx={{ width: "100%" }}
      >
        Building name already exists!
      </Alert>
    </Snackbar>
    </>
  );
};
