import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Typography,
  styled,
  TextField,
  Paper,
  Grid,
  Box,
  Tab,
  Tabs,
  Fab,
  MenuItem,
  Snackbar,
  Select,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import AdapterDayjs from "@mui/lab/AdapterDayjs";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { SaveCloseDialog } from "../components/SaveCloseDialog";
import { Add, Save, Close } from "@mui/icons-material";
import { doc, getDoc, or, setDoc, where, query, collection, getDocs } from "firebase/firestore";
import { WorkTypes } from "../components/WorkTypes";
import JobSheetModal from "components/JobSheetModal";
import ProjectStatus from "./ProjectStatus";
import { Forms } from "../components/Forms";
import Notes from "../components/Notes";
import Documents from "../components/Documents";
import { Context } from "context/Wrapper";
import { db } from "config/config";
import { useFetchCollectionDocs } from "apis/dataSnapshots";
import InfiniteScrollSelect from "components/shared/InfiniteScrollSelect";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import ClearIcon from '@mui/icons-material/Clear';
import { useSubscriptionState } from "context/SubscriptionContext";


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Item = styled(Paper)(({ theme }) => {
  return {
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.primary.main,
    background: theme.palette.secondary.main,
  };
});


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export const ProjectDetail = (props) => {
  // const groupId = props.groupId;

  const context = useContext(Context);

  const currentSubscription = useSubscriptionState()

  const { groupId, isPro, userId } = context?.state?.userProfile || {};


  const [groupUsers, setGroupUsers] = useState([]);



  





  // const user = context?.state?.userProfile || {},

  //   { showAllDataFlag } = context?.state || {};

  const contacts = useFetchCollectionDocs({
    collectionName: "contacts",
    conditions:
      userId && groupId && isPro
        ? [or(where("groupId", "==", groupId), where("userId", "==", userId))]
        : userId
          ? [where("userId", "==", userId)]
          : [],
  });

  const buildings = useFetchCollectionDocs({
    collectionName: "buildings",
    conditions:
      userId && groupId && isPro
        ? [or(where("groupId", "==", groupId), where("userId", "==", userId))]
        : userId
          ? [where("userId", "==", userId)]
          : [],
  });



  const { projectId } = useParams();

  const navigate = useNavigate();

  const [projectDetail, setProjectDetail] = useState();

  const [ownerDetails, setOwnerDetails] = useState(null);
  const [workTypes, setWorkTypes] = React.useState([]);

  // const [applicantDetails, setApplicantDetails] = React.useState(null);

  // const [filingRepDetails, setFilingRepDetails] = React.useState(null);

  // const [buildingDetails, setBuildingDetails] = React.useState(null);

  const [modalOpen, setModalOpen] = useState(false);



  const [flaggedMessage,setFlaggedMessage]=useState('This job requires attention')



  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };


  const getProject = async () => {
    const projectRef = doc(db, "projects", projectId);
    const initialProjectDetail = await getDoc(projectRef);

    setFlaggedMessage(initialProjectDetail.data().flaggedMessage||'This job requires attention')

    return setProjectDetail({
      ...initialProjectDetail.data(),
      id: initialProjectDetail.id,
    });
  };

  useEffect(() => {
    getProject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);




  let applicantDetails = contacts?.find(
    (contact) => projectDetail?.applicant === contact?.id,
  );
  let filingRepDetails = contacts?.find(
    (contact) => projectDetail?.filingRep === contact?.id,
  );
  //const buildingDetails = buildings?.find(
  //   (building) => building?.filingRep === building?.id,
  // );

  let buildingDetails = buildings?.find(
    (building) => projectDetail?.building === building?.id,
  );



  React.useEffect(() => {
    if (buildingDetails && buildingDetails.owner) {
      const fetchOwnerDetails = async () => {
        const ownerRef = doc(db, "contacts", buildingDetails?.owner);
        const initialOwnerDetail = await getDoc(ownerRef);
        const owner = initialOwnerDetail.data();
        setOwnerDetails(owner);
      };
      fetchOwnerDetails();
    }
  }, [buildingDetails]);

  useEffect(() => {
    const unsubscribe = db
      .collection("workTypes")
      .where("projectId", "==", projectId)
      .onSnapshot((snapshot) => {
        const workTypesData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setWorkTypes(workTypesData);
      });
    return () => unsubscribe();
  }, [projectId]);





  useEffect(() => {
    const fetchGroupUsers = async () => {
      if (!groupId) return;

      try {
        const q = query(
          collection(db, 'profiles'), 
          where('groupId', '==', groupId) 
        );

        const querySnapshot = await getDocs(q);
        const users = querySnapshot.docs.map((doc) => ({
          id: doc.id, 
          ...doc.data(),
        }));

        setGroupUsers(users);
      } catch (error) {
        console.error('Error fetching group users:', error);
      }
    };

    fetchGroupUsers();
  }, [groupId]);






  const [sheetData, setSheetData] = useState(null);

  useEffect(() => {
    if (projectDetail && buildings && contacts) {
      let tenantDetails = contacts?.find(
        (contact) => buildingDetails?.tenant === contact?.id,
      );

      let clientDetails = contacts?.find(
        (contact) => projectDetail?.client === contact?.id,
      );

      let projectMangerDetails = contacts?.find(
        (contact) => projectDetail?.projectManager === contact?.id,
      );

      let contractorDetails = contacts?.find(
        (contact) => projectDetail?.contractor === contact?.id,
      );

      const adjustedWorkTypes = workTypes.map((work) => {
        // Find the contact details for the current applicant
        const applicantDetails = contacts?.find(
          (contact) => work.applicant === contact.id
        );

        // Return the new structure with only the specified fields
        return {
          workType: work.workType,
          estimatedCost: work.estimatedCost,
          dateCreated: work.dateCreated,
          applicantDetails: applicantDetails || null, // Set to null if applicantDetails isn't found
        };
      });

      const formatValues = (...values) => {
        const formattedValues = values.filter(value => value !== "").join(", ");
        return formattedValues ? formattedValues : null;
      };

      setSheetData({
        jobNo: projectDetail?.customerReference || null,
        address: formatValues(
          buildingDetails?.houseNumber || "",
          buildingDetails?.streetName || "",
          buildingDetails?.city || "",
          buildingDetails?.state || "",
          buildingDetails?.ZIP || ""
        ),
        floors: buildingDetails?.floors || null,
        projectManger: projectMangerDetails?.fullName || null,
        owner: ownerDetails?.businessName || null,
        client: formatValues(
          clientDetails?.fullName || "",
          clientDetails?.businessName || "",
          clientDetails?.address1 || "",
          clientDetails?.city || "",
          clientDetails?.state || "",
          clientDetails?.ZIP || "",
          clientDetails?.phone || "",
          clientDetails?.email ? `Email: ${clientDetails.email}` : ""
        ),
        contractor: formatValues(
          contractorDetails?.fullName || "",
          contractorDetails?.businessName || "",
          contractorDetails?.address1 || "",
          contractorDetails?.city || "",
          contractorDetails?.state || "",
          contractorDetails?.ZIP || "",
          contractorDetails?.phone || "",
          contractorDetails?.email ? `Email: ${contractorDetails.email}` : ""
        ),
        tenant: tenantDetails?.businessName || "",
        workDescription: projectDetail?.workDescription || "",
        bin: buildingDetails?.BIN || "",
        block: buildingDetails?.block || "",
        borough: buildingDetails?.borough || "",
        cb: buildingDetails?.cb || "",
        houseNumber: buildingDetails?.houseNumber || "",
        lot: buildingDetails?.lot || "",
        workTypes: adjustedWorkTypes || [],
        status: projectDetail?.notes || ""
      });



    }

  }, [projectDetail, buildings, contacts, ownerDetails, workTypes])



  const [tab, setTab] = React.useState(0);

  const [open, setOpen] = React.useState(false);

  const [isDirty, setIsDirty] = React.useState(false);

  const [dialogOpen, setDialogOpen] = React.useState(false);

  const [failureOpen, setOpenFailure] = React.useState(false);
  const [duplicateOpen, setduplicateOpen] = React.useState(false);
  const [SubmitLoader, setSubmitLoader] = React.useState(false);

  let location = useLocation();



  const openSuccess = () => {
    setOpen(true);
  };

  const openFailure = () => {
    setOpenFailure(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleFailureClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenFailure(false);
  };


  const handleduplicateOpen = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setduplicateOpen(false);
  };

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };


  //   const onSubmit =async() => {
  //     setSubmitLoader(true)
  //     // Normalize the customerReference for comparison
  //     const normalizedCustomerReference = projectDetail.customerReference.trim().toLowerCase();
  //     const thisProject = doc(db, "projects", projectId);

  //     // Check if any other project has the same customerReference (case-insensitive)
  //     await db.collection("projects")
  //       .get()
  //       .then((querySnapshot) => {
  //         // Check if there's any other document with the same customerReference but different ID
  //         const isDuplicate = querySnapshot.docs.some(doc => {
  //           const docData = doc.data();
  //           const docCustomerReference = docData.customerReference ? docData.customerReference.trim().toLowerCase() : '';
  //           return doc.id !== projectId && docCustomerReference === normalizedCustomerReference;
  //         });

  //         if (!isDuplicate) {
  //           // If no duplicates, proceed to update the document with the original input
  //           setDoc(thisProject, {
  //             ...projectDetail,
  //             customerReference: projectDetail.customerReference.trim(), // Store original case-sensitive version
  //           }, { merge: true })
  //             .then(() => {
  //               setIsDirty(false);
  //               openSuccess();
  //               setSubmitLoader(false)
  //             })
  //             .catch((error) => {
  //               console.error("Error updating document: ", error);
  //               openFailure();
  //               setSubmitLoader(false)
  //             });

  //         } else {
  //           // If a matching document is found, display an error
  //           setduplicateOpen(true);
  //           setSubmitLoader(false)
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Error checking for unique customerReference: ", error);
  //         setSubmitLoader(false)
  //       });
  // };
  const onSubmit = async () => {
    setSubmitLoader(true);
    const normalizedCustomerReference = projectDetail.customerReference.trim().toLowerCase();
    const thisProject = doc(db, "projects", projectId);

    try {
      const q = query(collection(db, "projects"), where("groupId", "==", groupId));
      const querySnapshot = await getDocs(q);
      const isDuplicate = querySnapshot.docs.some(doc => {
        const docData = doc.data();
        const docCustomerReference = docData.customerReference ? docData.customerReference.trim().toLowerCase() : '';
        return doc.id !== projectId && docCustomerReference === normalizedCustomerReference;
      });

      if (!isDuplicate) {
        await setDoc(thisProject, {
          ...projectDetail,
          flaggedMessage:
          projectDetail.flaggedMessage === undefined || projectDetail.flaggedMessage === ""
              ? "This job requires attention"
              : projectDetail.flaggedMessage,
          customerReference: projectDetail.customerReference.trim(),
        }, { merge: true });

        setIsDirty(false);
        openSuccess();
        return true; // Indicate success
      } else {
        setduplicateOpen(true);
        return false; // Indicate duplicate
      }
    } catch (error) {
      console.error("Error:", error);
      openFailure();
      return false; // Indicate failure
    } finally {
      setSubmitLoader(false);
    }
  };



  const handleDateChange = (fieldName, value) => {
    let currentProjectDetail = projectDetail;
    setIsDirty(true);
    currentProjectDetail[fieldName] = JSON.stringify(value);
    setProjectDetail({ ...currentProjectDetail });
  };


  const saveAndClose = async () => {
    const isSuccess = await onSubmit();

    if (isSuccess) {
      navigate("/projects", { state: { feedback: { message: 'Project saved successfully!', severity: 'success' } } });
    } else {
      setDialogOpen(false)
    }
  };


  const handleChange = (e) => {
    let currentProjectDetail = projectDetail;
    currentProjectDetail[e.target.name] = e.target.value;
    setIsDirty(true);
    setProjectDetail({ ...currentProjectDetail });
  };

  const discardAndClose = () => {
    navigate("/projects");
  };

  const handleChangeNotes = (notes) => {
    let currentProjectDetail = projectDetail;

    setProjectDetail({ ...currentProjectDetail, notes: notes });
  };

  const handleChangeDocuments = (data) => {
    setProjectDetail((prevVal) => ({ ...prevVal, documents: data }));
  };

  const handleCloseProject = () => {
    if (isDirty && !props.isInactive) {
      setDialogOpen(true);
      return;
    }

    if (location?.state?.fromBuildingReferencesJobDetails?.fromBuildingReferencesJob) {
      navigate(`/buildings/details/${location?.state?.fromBuildingReferencesJobDetails.buildingId}`, { state: { FromJobDetailsReferencesBuilding: true } });
    } else {
      navigate("/projects");
    }
  };

  // React.useEffect(() => {
  //   if (projectDetail) {
  //     const fetchApplicantDetails = async () => {
  //       const applicantRef = doc(db, "contacts", projectDetail?.applicant);
  //       const initialApplicantDetail = await getDoc(applicantRef);
  //       const applicant = initialApplicantDetail.data();
  //       // setApplicantDetails(applicant);
  //     console.log(applicant)
  //     };
  //     fetchApplicantDetails();
  //   }
  // }, [projectDetail, projectDetail?.applicant]);


  // React.useEffect(() => {
  //   if (projectDetail) {
  //     const fetchFilingRepDetails = async () => {
  //       const filingRepRef = doc(db, "contacts", projectDetail?.filingRep);
  //       const initialFilingRepDetail = await getDoc(filingRepRef);
  //       const filingRep = initialFilingRepDetail.data();
  //       setFilingRepDetails(filingRep);
  //     };
  //     fetchFilingRepDetails();
  //   }
  // }, [projectDetail, projectDetail?.filingRep]);

  // React.useEffect(() => {
  //   if (projectDetail) {
  //     const fetchBuildingDetails = async () => {
  //       const buildingRef = doc(db, "buildings", projectDetail?.building);
  //       const initialBuildingDetail = await getDoc(buildingRef);
  //       const building = initialBuildingDetail.data();
  //       setBuildingDetails(building);
  //     };
  //     fetchBuildingDetails();
  //   }
  // }, [projectDetail]);

  const hasRequiredFields = () => {
    return !!projectDetail?.customerReference;
  };




  const handleFlagCheckboxChange = (event) => {
    setIsDirty(true);
    setProjectDetail((prev)=>{
     return {...prev,flagged:event.target.checked}
    })
    
  };

  const handleFlaggedTextChange = (event) => {
    setIsDirty(true);
    setFlaggedMessage(event.target.value)
    setProjectDetail((prev)=>{
      return {...prev,flaggedMessage:event.target.value}
     })
  };




  if (!projectDetail) return null;

  return (
    <>
      <Typography sx={{ textTransform: "uppercase",display: "flex", alignItems: "center"}} align="left" variant="h4">
      {projectDetail.flagged && (
      <Box
       sx={{
         width: 16,
         height: 16,
        backgroundColor: "red",
         borderRadius: "50%",
         display: "inline-block",
         marginRight: 1, 
      }}
    />
  )}
        {projectDetail.customerReference}
      </Typography>
      <Box sx={{ borderBottom: 1, borderColor: "divider", paddingLeft: 0 }}>
        <Tabs
          value={tab}
          onChange={handleChangeTab}
          aria-label="tab navigation"
        >
          <Tab label="Details" />
          <Tab label="Work Types" />
          <Tab label="Documents" />
          <Tab label="DOB status" />
          <Tab label="Notes" />
          <Tab label="Forms" />
        </Tabs>
      </Box>
      <TabPanel value={tab} index={0}>
        <form name="projectForm" id="projectForm">
          <Grid container spacing={2}>





          <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={projectDetail.flagged ? projectDetail.flagged:false}
                    onChange={handleFlagCheckboxChange}
                  />
                }
                label="Flag this Job"
              />
            </Grid>

            {projectDetail.flagged && (
              <Grid item xs={12}>
                <TextField
                  label="Flagged Message"
                  variant="outlined"
                  value={flaggedMessage}
                  onChange={handleFlaggedTextChange}
                  fullWidth
                  InputProps={{
                    style: { color: "red" },
                  }}
                  // helperText="Default message will be (This job requires attention) if no value is set."
                />
              </Grid>
            )}

            

            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="Job ID"
                variant="outlined"
                name="customerReference"
                value={projectDetail.customerReference}
                onInput={handleChange}
                fullWidth
                required
              />
            </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              name="jobAlias"
              label="Job Alias"
              variant="outlined"
              value={projectDetail.jobAlias}
              onInput={handleChange}
              fullWidth
            />
          </Grid>
            <Grid item xs={12} md={6} lg={4}>
              {/* <TextField
                label={"Building"}
                name="building"
                select
                onChange={handleChange}
                value={projectDetail.building}
                fullWidth
              >
                {buildings.map((building) => (
                  <MenuItem value={building.id}>
                    {building.customerReference}
                  </MenuItem>
                ))}
              </TextField> */}
              <InfiniteScrollSelect
                collectionName="buildings"
                label="customerReference"
                onChange={handleChange}
                value={projectDetail.building}
                name="building"
                inputLabel="Building"
              />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="Floor(s)"
                variant="outlined"
                name="floors"
                value={projectDetail.floors}
                onChange={handleChange}
                fullWidth

              />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <TextField
                name="DOBNumber"
                label="DOB reference (BIS or Now!)"
                variant="outlined"
                value={projectDetail.DOBNumber}
                onInput={handleChange}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              {/* <TextField
                label={"Tenant"}
                name="tenant"
                select
                onChange={handleChange}
                value={buildingDetail.tenant}
                fullWidth
              >
                {contacts.map((contact) => (
                  <MenuItem value={contact.id}>{contact.fullName}</MenuItem>
                ))}
              </TextField> */}
              <InfiniteScrollSelect
                  collectionName="contacts"
                  label="fullName"
                  onChange={handleChange}
                  value={projectDetail.tenant}
                  inputLabel="Tenant"
                  name="tenant"
              />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              {/* <TextField
                label={"Client"}
                name="client"
                onChange={handleChange}
                value={projectDetail.client}
                select
                fullWidth
              >
                {contacts.map((contact) => (
                  <MenuItem value={contact.id}>{contact.fullName}</MenuItem>
                ))}
              </TextField> */}
              <InfiniteScrollSelect
                collectionName="contacts"
                label="fullName"
                onChange={handleChange}
                value={projectDetail.client}
                name="client"
                inputLabel="Client"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              {/* <TextField
                label={"Applicant"}
                name="applicant"
                onChange={handleChange}
                select
                value={projectDetail.applicant}
                fullWidth
              >
                {contacts.map((contact) => (
                  <MenuItem value={contact.id}>{contact.fullName}</MenuItem>
                ))}
              </TextField> */}
              <InfiniteScrollSelect
                collectionName="contacts"
                label="fullName"
                onChange={handleChange}
                value={projectDetail.applicant}
                name="applicant"
                inputLabel="Applicant"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              {/* <TextField
                label={"Filing representative"}
                name="filingRep"
                onChange={handleChange}
                select
                value={projectDetail.filingRep}
                fullWidth
              >
                {contacts.map((contact) => (
                  <MenuItem value={contact.id}>{contact.fullName}</MenuItem>
                ))}
              </TextField> */}
              <InfiniteScrollSelect
                collectionName="contacts"
                label="fullName"
                onChange={handleChange}
                value={projectDetail.filingRep}
                name="filingRep"
                inputLabel="Filing representative"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              {/* <TextField
                label={"Project manager"}
                name="projectManager"
                onChange={handleChange}
                select
                value={projectDetail.projectManager}
                fullWidth
              >
                {contacts.map((contact) => (
                  <MenuItem value={contact.id}>{contact.fullName}</MenuItem>
                ))}
              </TextField> */}
              <InfiniteScrollSelect
                collectionName="contacts"
                label="fullName"
                onChange={handleChange}
                value={projectDetail.projectManager}
                name="projectManager"
                inputLabel="Project manager"
              />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <InfiniteScrollSelect
                collectionName="contacts"
                label="fullName"
                onChange={handleChange}
                value={projectDetail.contractor}
                name="contractor"
                inputLabel="Contractor"
              />
            </Grid>


            {currentSubscription?.isPro && (
            <Grid item xs={12} md={6} lg={4}>
           <TextField
          label="Assigned To"
           name="assignedTo"
           select
           onChange={handleChange}
           value={projectDetail.assignedTo || ""}
          fullWidth
           InputProps={{
           endAdornment: projectDetail.assignedTo ? (
            <InputAdornment position="end">
              <IconButton
                onClick={() =>
                  handleChange({ target: { name: 'assignedTo', value: '' } })
                }
                style={{ marginRight: '10px' }}
              >
                <ClearIcon />
              </IconButton>
            </InputAdornment>
           ) : null,
           }}
          >
           {groupUsers.length > 0 ? (
           groupUsers.map((user) => (
            <MenuItem key={user.id} value={user.id}>
              {user.name} ({user.email})
            </MenuItem>
          ))
          ) : (
          <MenuItem disabled>No users available</MenuItem>
        )}
      </TextField>
    </Grid>
      )}



            <Grid item xs={12} md={12} lg={6}>
              <TextField
                label="Work Description"
                variant="outlined"
                multiline
                rows="6"
                name="workDescription"
                value={projectDetail.workDescription}
                onInput={handleChange}
                fullWidth
              />
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item xs={12} md={6} lg={4}>
                <DatePicker
                  label="Start date"
                  value={
                    projectDetail.startDate
                      ? JSON.parse(projectDetail.startDate)
                      : null
                  }
                  onChange={(newValue) =>
                    handleDateChange("startDate", newValue)
                  }
                  fullWidth
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <DatePicker
                  label="End date"
                  value={
                    projectDetail.endDate
                      ? JSON.parse(projectDetail.endDate)
                      : null
                  }
                  onChange={(newValue) => handleDateChange("endDate", newValue)}
                  fullWidth
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </Grid>
            </LocalizationProvider>
          </Grid>
        </form>
        <Box
          sx={{
            position: "fixed",
            bottom: "1rem",
            right: "1rem",
            "& > :not(style)": { m: 1 },
          }}
        >
          {!props.isInactive && (
            <>
              <Fab
                color="primary"
                variant="extended"
                onClick={handleModalOpen}
              >
                <DownloadForOfflineIcon sx={{ mr: 1 }} />
                Generate Job Sheet
              </Fab>
              <Fab
                color="primary"
                aria-label="add"
                variant="extended"
                disabled={SubmitLoader || (!isDirty && !hasRequiredFields()) || props.isInactive}
                form="projectForm"
                onClick={onSubmit}
              >
                <Save sx={{ mr: 1 }} />
                Save
              </Fab>
            </>
          )}

          <Fab
            color="secondary"
            aria-label="add"
            variant="extended"
            onClick={handleCloseProject}
          >
            <Close sx={{ mr: 1 }} />
            Close
          </Fab>

        </Box>
        <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Project successfully saved!
          </Alert>
        </Snackbar>
        <Snackbar
          open={failureOpen}
          autoHideDuration={2000}
          onClose={handleFailureClose}
        >
          <Alert
            onClose={handleFailureClose}
            severity="error"
            sx={{ width: "100%" }}
          >
            Something went wrong!
          </Alert>
        </Snackbar>
        <Snackbar
          open={duplicateOpen}
          autoHideDuration={2000}
          onClose={handleduplicateOpen}
        >
          <Alert
            onClose={handleduplicateOpen}
            severity="error"
            sx={{ width: "100%" }}
          >
            Job ID already exists!
          </Alert>
        </Snackbar>
        <SaveCloseDialog
          open={dialogOpen}
          saveAndClose={saveAndClose}
          discardAndClose={discardAndClose}
          setDialogOpen={setDialogOpen}
        />


        <div>
          <JobSheetModal sheetData={sheetData} open={modalOpen} onClose={handleModalClose} />
        </div>
      </TabPanel>



      <TabPanel value={tab} index={1}>
        <WorkTypes
          userId={projectDetail.userId}
          db={db}
          projectId={projectId}
          projectDOB={projectDetail.DOBNumber}
          contacts={contacts}
          isInactive={props.isInactive}
        />
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <Documents
          documents={projectDetail?.documents || []}
          projectId={projectId}
          onSubmit={onSubmit}
          getProject={getProject}
          projectData={projectDetail}
          isInactive={props.isInactive}
          contacts={contacts}
        />
      </TabPanel>
      <TabPanel value={tab} index={3}>
        {!props.isInactive ? (
          <div style={{ height: "calc(100vh - 230px)" }}>
            <ProjectStatus
              thisProject={projectDetail.DOBNumber}
              workTypeIDs={workTypes.map((workType) => workType.dobNumber)}
            />
          </div>
        ) : (
          <div style={{ height: "calc(100vh - 230px)" }}>
            This feature is available with an active subscription.
          </div>
        )}
      </TabPanel>
      <TabPanel value={tab} index={4}>
        <Notes
          notes={projectDetail.notes}
          handleChangeNotes={handleChangeNotes}
          onSubmit={onSubmit}
          isInactive={props.isInactive}
        />
      </TabPanel>
      <TabPanel value={tab} index={5}>
        <Forms
          projectId={projectId}
          applicantDetails={applicantDetails}
          filingRepDetails={filingRepDetails}
          buildingDetails={{ ...buildingDetails, DOBNumber: projectDetail.DOBNumber }}
          extraFieldsToAutoFill={{jobNumber:projectDetail.customerReference}}
          ownerDetails={ownerDetails}
          userId={projectDetail.userId}
          db={db}
          groupId={groupId}
          isInactive={props.isInactive}
        />
      </TabPanel>

    </>
  );
};
